import styled from 'styled-components';
import { PanelContainerStyles } from 'components/GenericStyles/generic.styles';
import { DesktopWidth, TabletWidth, ThemeColors } from 'styles/constants';

export const LoadingPanelContainerStyles = styled(PanelContainerStyles)`
  padding: 16px;
  justify-content: flex-start;

  @media (min-width: ${TabletWidth}) {
    padding: 24px;
  }
`;

export const LoadingWheel = styled.div`
  border-width: 16px;
  border-style: solid;
  border-color: ${ThemeColors.TassoViolet} ${ThemeColors.TassoViolet} ${ThemeColors.TassoViolet} ${ThemeColors.TassoLightViolet};
  width: 96px;
  height: 96px;
  border-radius: 50%;
  animation: spin 1.5s infinite;
  position: relative;
  
  margin: 16px auto;
  
  &:before, &:after {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: ${ThemeColors.TassoViolet};
    position: absolute;
    left: 1px;
  }
  
  &:before {
    top:0px;
  }
  
  &:after {
    bottom: 0px;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
`;

export const LoadingPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25%;

  @media (min-width: ${DesktopWidth}) {
    margin-top: 15%;
  }
`;
