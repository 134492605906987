import React from 'react';
import RotateDeviceIcon from 'assets/RotateDeviceIcon';
import { ThemeFonts } from 'styles/constants';
import { RotateDeviceWrapper } from './rotatePanel.styles';

const RotateDevicePanel = () => {
  return (
    <RotateDeviceWrapper>
      <RotateDeviceIcon />
      <ThemeFonts.DisplayMedium margin="40px 0px 0px 0px">Please rotate your device</ThemeFonts.DisplayMedium>
      <ThemeFonts.TitleLarge>This experience is better in portrait mode</ThemeFonts.TitleLarge>
    </RotateDeviceWrapper>
  );
};

export default RotateDevicePanel;
