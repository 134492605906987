import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful';

export interface TypeInfoTextPanelFields {
  name: EntryFieldTypes.Symbol;
  titleText: EntryFieldTypes.Text;
  subText?: EntryFieldTypes.Text;
  tertiaryText?: EntryFieldTypes.Text;
  swipeText?: EntryFieldTypes.Text;
  backgroundIcon?: EntryFieldTypes.AssetLink;
  nextIcon?: EntryFieldTypes.AssetLink;
  secondaryButtonText?: EntryFieldTypes.Text;
  helpIcon: EntryFieldTypes.AssetLink;
  nextButtonText: EntryFieldTypes.Symbol;
  nextButtonPrefixText?: EntryFieldTypes.Symbol;
  nextButtonLink?: EntryFieldTypes.Symbol;
}

export type TypeInfoTextPanelSkeleton = EntrySkeletonType<TypeInfoTextPanelFields, 'infoTextPanel'>;
export type TypeInfoTextPanel<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<
  TypeInfoTextPanelSkeleton,
  Modifiers,
  Locales
>;

export function isTypeInfoTextPanel<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeInfoTextPanel<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'infoTextPanel';
}

export type TypeInfoTextPanelWithoutLinkResolutionResponse = TypeInfoTextPanel<'WITHOUT_LINK_RESOLUTION'>;
export type TypeInfoTextPanelWithoutUnresolvableLinksResponse = TypeInfoTextPanel<'WITHOUT_UNRESOLVABLE_LINKS'>;
export type TypeInfoTextPanelWithAllLocalesResponse<Locales extends LocaleCode = LocaleCode> = TypeInfoTextPanel<
  'WITH_ALL_LOCALES',
  Locales
>;
export type TypeInfoTextPanelWithAllLocalesAndWithoutLinkResolutionResponse<Locales extends LocaleCode = LocaleCode> =
  TypeInfoTextPanel<'WITHOUT_LINK_RESOLUTION' | 'WITH_ALL_LOCALES', Locales>;
export type TypeInfoTextPanelWithAllLocalesAndWithoutUnresolvableLinksResponse<
  Locales extends LocaleCode = LocaleCode,
> = TypeInfoTextPanel<'WITHOUT_UNRESOLVABLE_LINKS' | 'WITH_ALL_LOCALES', Locales>;
