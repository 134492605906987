export const PATIENT_API_URL = import.meta.env.VITE_PATIENT_API_URL || '';

export const httpRequestHeaders = ({ recaptcha = undefined }: { recaptcha?: string }): HeadersInit => {
  const requestHeaders = new Headers();
  requestHeaders.set('Content-Type', 'application/json');
  requestHeaders.set('X-Frame-Options', 'DENY');
  requestHeaders.set('X-Content-Type-Options', 'nosniff');
  requestHeaders.set('Referrer-Policy', 'strict-origin-when-cross-origin');
  if (recaptcha) {
    requestHeaders.set('recaptcha', recaptcha);
  }
  return requestHeaders;
};
