import styled from 'styled-components';
import { TabletWidth, ThemeColors } from 'styles/constants';

export const LocationListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 16px 0px 16px;
  overflow-y: scroll;

  @media (min-width: ${TabletWidth}) {
    max-height: 95vh;
    box-sizing: border-box;
    padding-bottom: 80px;
    margin: 0;
  }
`;

export const LocationListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const LocationListDivider = styled.div`
  border-top: 1px solid ${ThemeColors.CloudyGray};
`;

export const LocationListSpacer = styled.div`
  padding-top: 248px;
  background-color: ${ThemeColors.TassoWhite};
`;

export const LocationListGradient = styled.div`
  display: flex;
  align-items: flex-end;
  position: fixed;
  bottom: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, ${ThemeColors.TassoWhite} 19%);
  width: 100%;
  height: 148px;
  padding: 8px;
  box-sizing: border-box;
`;
