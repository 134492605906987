import { useMemo, useState, useEffect } from 'react';
import { DesktopWidth, TabletWidth } from 'styles/constants';

declare let window: Window;
declare let navigator: Navigator;

function useMediaQuery(query: string) {
  const mediaQuery = useMemo(() => window.matchMedia(query), [query]);
  const [match, setMatch] = useState(mediaQuery.matches);

  useEffect(() => {
    const onChange = () => setMatch(mediaQuery.matches);
    mediaQuery.addEventListener('change', onChange);

    return () => mediaQuery.removeEventListener('change', onChange);
  }, [mediaQuery]);

  return match;
}

export function useMediaQueries() {
  const isTabletWidth = useMediaQuery(`(min-width: ${TabletWidth})`);
  const isDesktopWidth = useMediaQuery(`(min-width: ${DesktopWidth})`);

  return { isTabletWidth, isDesktopWidth };
}

export function isTouchDevice() {
  let hasTouchScreen = false;

  if ('maxTouchPoints' in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if ('msMaxTouchPoints' in navigator) {
    hasTouchScreen = navigator['msMaxTouchPoints'] > 0;
  } else {
    const mQ = window && window.matchMedia('(pointer:coarse)');
    if (mQ && mQ.media === '(pointer:coarse)') {
      hasTouchScreen = !!mQ.matches;
    } else if ('orientation' in window) {
      hasTouchScreen = true; // deprecated, but good fallback
    } else {
      hasTouchScreen = true; // fallback to true
    }
  }

  return hasTouchScreen;
}
