import React, { SVGProps } from 'react';

export const QuestionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_5908_8498)">
      <path
        d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.00519941 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6934 24 14.3734 24 12C23.9966 8.81843 22.7313 5.76814 20.4816 3.51843C18.2319 1.26872 15.1816 0.00335979 12 0ZM12 19.3846C11.7262 19.3846 11.4585 19.3034 11.2308 19.1513C11.0031 18.9991 10.8256 18.7829 10.7208 18.5299C10.616 18.2769 10.5886 17.9985 10.642 17.7299C10.6954 17.4613 10.8273 17.2146 11.0209 17.0209C11.2146 16.8273 11.4613 16.6954 11.7299 16.642C11.9985 16.5886 12.2769 16.616 12.5299 16.7208C12.7829 16.8256 12.9991 17.003 13.1513 17.2307C13.3034 17.4584 13.3846 17.7261 13.3846 18C13.3846 18.3672 13.2387 18.7194 12.9791 18.9791C12.7194 19.2387 12.3672 19.3846 12 19.3846ZM12.9231 13.7631V13.8462C12.9231 14.091 12.8258 14.3258 12.6527 14.4989C12.4796 14.672 12.2448 14.7692 12 14.7692C11.7552 14.7692 11.5204 14.672 11.3473 14.4989C11.1742 14.3258 11.0769 14.091 11.0769 13.8462V12.9231C11.0769 12.6783 11.1742 12.4435 11.3473 12.2704C11.5204 12.0972 11.7552 12 12 12C13.5265 12 14.7692 10.9615 14.7692 9.69231C14.7692 8.42308 13.5265 7.38461 12 7.38461C10.4735 7.38461 9.23077 8.42308 9.23077 9.69231V10.1538C9.23077 10.3987 9.13352 10.6334 8.96041 10.8066C8.7873 10.9797 8.55251 11.0769 8.3077 11.0769C8.06288 11.0769 7.82809 10.9797 7.65498 10.8066C7.48187 10.6334 7.38462 10.3987 7.38462 10.1538V9.69231C7.38462 7.40192 9.45462 5.53846 12 5.53846C14.5454 5.53846 16.6154 7.40192 16.6154 9.69231C16.6154 11.6977 15.0277 13.3765 12.9231 13.7631Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_5908_8498">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default QuestionIcon;
