import React from 'react';

import TimeClockIcon from 'assets/TimerClockIcon';
import HelpButton from 'components/SharedComponents/HelpButton';
import NextButton from 'components/SharedComponents/NextButton';
import { TypeOverviewPanelWithoutUnresolvableLinksResponse } from 'contentmodels';

import { ThemeColors, ThemeFonts } from 'styles/constants';
import {
  OverviewStepContent,
  OverviewStepWrapper,
  HeaderWrapper,
  OverviewPanelContainer,
  ContentWrapper,
  TitleText,
} from './overviewPanel.styles';
import { TimeWrapper } from '../GenericStyles/generic.styles';

const OverviewPanel = (props: {
  panel: TypeOverviewPanelWithoutUnresolvableLinksResponse;
  openHelpModal: () => void;
}) => {
  const { panel } = props;

  const { titleText, nextButtonText, timeText, overviewSteps } = panel.fields;

  return (
    <OverviewPanelContainer>
      <HeaderWrapper>
        <ContentWrapper>
          {titleText && <TitleText>{titleText}</TitleText>}
          {timeText && (
            <TimeWrapper>
              <TimeClockIcon height={17} width={17} />
              <ThemeFonts.BodyMedium color={ThemeColors.HeavyGray}>{timeText}</ThemeFonts.BodyMedium>
            </TimeWrapper>
          )}
        </ContentWrapper>
        <HelpButton {...props} />
      </HeaderWrapper>
      <ContentWrapper>
        {overviewSteps &&
          overviewSteps.map((overview, index: number) => (
            <OverviewStepWrapper key={index}>
              <OverviewStepContent>
                {overview?.fields.stepNumber && (
                  <img
                    src={overview.fields.stepNumber.fields.file?.url}
                    alt={overview.fields.stepNumber.fields.description}
                  />
                )}
                {overview?.fields.stepIcon && (
                  <img
                    src={overview.fields.stepIcon.fields.file?.url}
                    alt={overview.fields.stepIcon.fields.description}
                  />
                )}
                {overview?.fields.stepName && (
                  <ThemeFonts.TitleLarge color={ThemeColors.TassoBlue}>
                    {overview.fields.stepName}
                  </ThemeFonts.TitleLarge>
                )}
              </OverviewStepContent>
              {overview?.fields.timeText && (
                <TimeWrapper>
                  <TimeClockIcon height={13} width={13} />
                  <ThemeFonts.BodySmall color={ThemeColors.HeavyGray}>{overview?.fields.timeText}</ThemeFonts.BodySmall>
                </TimeWrapper>
              )}
            </OverviewStepWrapper>
          ))}
      </ContentWrapper>
      <NextButton nextButtonText={nextButtonText} />
    </OverviewPanelContainer>
  );
};

export default OverviewPanel;
