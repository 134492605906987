import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful';
import type { TypeOverviewStepSkeleton } from './TypeOverviewStep';

export interface TypeOverviewPanelFields {
  name: EntryFieldTypes.Symbol;
  titleText: EntryFieldTypes.Symbol;
  timeText?: EntryFieldTypes.Symbol;
  overviewSteps?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeOverviewStepSkeleton>>;
  helpIcon?: EntryFieldTypes.AssetLink;
  swipeText?: EntryFieldTypes.Symbol;
  nextIcon?: EntryFieldTypes.AssetLink;
  nextButtonText: EntryFieldTypes.Symbol;
}

export type TypeOverviewPanelSkeleton = EntrySkeletonType<TypeOverviewPanelFields, 'overviewPanel'>;
export type TypeOverviewPanel<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<
  TypeOverviewPanelSkeleton,
  Modifiers,
  Locales
>;

export function isTypeOverviewPanel<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeOverviewPanel<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'overviewPanel';
}

export type TypeOverviewPanelWithoutLinkResolutionResponse = TypeOverviewPanel<'WITHOUT_LINK_RESOLUTION'>;
export type TypeOverviewPanelWithoutUnresolvableLinksResponse = TypeOverviewPanel<'WITHOUT_UNRESOLVABLE_LINKS'>;
export type TypeOverviewPanelWithAllLocalesResponse<Locales extends LocaleCode = LocaleCode> = TypeOverviewPanel<
  'WITH_ALL_LOCALES',
  Locales
>;
export type TypeOverviewPanelWithAllLocalesAndWithoutLinkResolutionResponse<Locales extends LocaleCode = LocaleCode> =
  TypeOverviewPanel<'WITHOUT_LINK_RESOLUTION' | 'WITH_ALL_LOCALES', Locales>;
export type TypeOverviewPanelWithAllLocalesAndWithoutUnresolvableLinksResponse<
  Locales extends LocaleCode = LocaleCode,
> = TypeOverviewPanel<'WITHOUT_UNRESOLVABLE_LINKS' | 'WITH_ALL_LOCALES', Locales>;
