import React, { SVGProps } from 'react';

export const SpeakerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_5458_8285)">
      <path
        d="M18.375 3.50008V24.5001C18.3749 24.6638 18.3288 24.8242 18.2421 24.9631C18.1553 25.1019 18.0313 25.2136 17.8842 25.2855C17.7371 25.3574 17.5728 25.3866 17.41 25.3698C17.2471 25.3529 17.0922 25.2907 16.963 25.1902L9.32422 19.2501H4.375C3.91087 19.2501 3.46575 19.0657 3.13756 18.7375C2.80937 18.4093 2.625 17.9642 2.625 17.5001V10.5001C2.625 10.0359 2.80937 9.59083 3.13756 9.26264C3.46575 8.93445 3.91087 8.75008 4.375 8.75008H9.32422L16.963 2.80992C17.0922 2.70943 17.2471 2.64723 17.41 2.63038C17.5728 2.61353 17.7371 2.64271 17.8842 2.71461C18.0313 2.78651 18.1553 2.89823 18.2421 3.03709C18.3288 3.17594 18.3749 3.33635 18.375 3.50008ZM21.875 10.5001C21.6429 10.5001 21.4204 10.5923 21.2563 10.7564C21.0922 10.9205 21 11.143 21 11.3751V16.6251C21 16.8571 21.0922 17.0797 21.2563 17.2438C21.4204 17.4079 21.6429 17.5001 21.875 17.5001C22.1071 17.5001 22.3296 17.4079 22.4937 17.2438C22.6578 17.0797 22.75 16.8571 22.75 16.6251V11.3751C22.75 11.143 22.6578 10.9205 22.4937 10.7564C22.3296 10.5923 22.1071 10.5001 21.875 10.5001ZM25.375 8.75008C25.1429 8.75008 24.9204 8.84226 24.7563 9.00636C24.5922 9.17045 24.5 9.39301 24.5 9.62508V18.3751C24.5 18.6071 24.5922 18.8297 24.7563 18.9938C24.9204 19.1579 25.1429 19.2501 25.375 19.2501C25.6071 19.2501 25.8296 19.1579 25.9937 18.9938C26.1578 18.8297 26.25 18.6071 26.25 18.3751V9.62508C26.25 9.39301 26.1578 9.17045 25.9937 9.00636C25.8296 8.84226 25.6071 8.75008 25.375 8.75008Z"
        fill="#091F5B"
      />
    </g>
    <defs>
      <clipPath id="clip0_5458_8285">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SpeakerIcon;
