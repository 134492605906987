import React, { SVGProps } from 'react';

export const TimerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_5360_4770)">
      <path
        d="M17.6857 26.0355C23.2726 24.929 26.9046 19.5029 25.7981 13.916C24.6915 8.32905 19.2654 4.697 13.6785 5.80355C8.09158 6.91009 4.45953 12.3362 5.56608 17.9231C6.67263 23.51 12.0988 27.1421 17.6857 26.0355Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.03743 5.6037L2.08746 10.0108"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5908 2.3252L25.9979 5.27516"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4072 9.48218L15.6822 15.9196L22.1197 14.6446"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5360_4770">
        <rect width="30" height="30" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default TimerIcon;
