import React from 'react';

// eslint-disable-next-line import/no-unresolved
import { useSwiper } from 'swiper/react';
import { ThemeFonts } from 'styles/constants';
import { NextButtonContainer, NextButtonStyles } from './nextButton.styles';

interface NextButtonProps {
  nextButtonText: string;
  nextButtonLink?: string;
}
// By default this button trigger swiper next.  If there is a nextButtonLink defined, that will be used instead.
const NextButton = (props: NextButtonProps) => {
  const swiper = useSwiper();

  // TODO Remove me once Mayo is hosted on Main/Prod AND next button text for their celebration does not have the pipe!
  let text = props.nextButtonText;
  if (text.includes('|')) {
    text = text.split('|')[1];
  }
  // END TODO REMOVE
  return (
    <NextButtonContainer>
      <NextButtonStyles
        onClick={() => (props.nextButtonLink ? (window.location.href = props.nextButtonLink) : swiper.slideNext())}
      >
        <ThemeFonts.LabelLarge>{text}</ThemeFonts.LabelLarge>
      </NextButtonStyles>
    </NextButtonContainer>
  );
};

export default NextButton;
