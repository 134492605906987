import React from 'react';

import { AdvancedMarker, Map } from '@vis.gl/react-google-maps';
import MapPinIcon from 'assets/MapPinIcon';
import { useGlobalStore } from 'contexts/mainGlobalContext';
import { ThemeColors } from 'styles/constants';
import { LocationMapWrapper } from './dropOffLocationMap.styles';
import DropOffLocationMapMarker from './DropOffLocationMapMarker';
import { LocationProps } from '../DropOffLocation';
import TransitionButtons from '../TransitionButtons';
import { CarrierLocation } from '../types';

interface DropOffLocationMapProps extends LocationProps {
  searchOrigin?: { latitude: number; longitude: number };
  locations: CarrierLocation[];
  includeTransitionButtons: boolean;
}

const DropOffLocationMap = (props: DropOffLocationMapProps) => {
  const { openLocationIndex, setOpenLocationIndex } = useGlobalStore();

  const getBookmarkIndex = () => {
    if (props.activeBookmarkIndex && props.activeBookmarkIndex !== -1) {
      return props.activeBookmarkIndex;
    }

    const lsBookmarkIndex = localStorage.getItem('activeBookmarkIndex');
    if (lsBookmarkIndex) {
      return parseInt(lsBookmarkIndex);
    }
  };

  const onMarkerClick = (locationIndex: number) => {
    if (openLocationIndex === locationIndex) {
      setOpenLocationIndex(null);
    } else {
      setOpenLocationIndex(locationIndex);
    }
  };

  // If a location was bookmarked then it should be the initial focus on the map. If there is not a
  // bookmarked location then we will just use the closest location (they're sorted by distance already).
  const bookmarkIndex = getBookmarkIndex();
  const bookmarkedLocation = props.locations.find((location) => location.locationIndex === bookmarkIndex);
  const centerLocation = bookmarkedLocation ?? props.locations[0];

  return (
    <LocationMapWrapper>
      <Map
        mapId="DropOffLocationMap"
        defaultCenter={{ lat: centerLocation.latitude, lng: centerLocation.longitude }}
        fullscreenControl={false}
        defaultZoom={13}
        zoomControl={true}
        zoomControlOptions={{ position: google.maps.ControlPosition.RIGHT_TOP }}
        streetViewControl={false}
        mapTypeControl={false}
        onClick={undefined}
        clickableIcons={false}
      >
        {props.searchOrigin && (
          <AdvancedMarker position={{ lat: props.searchOrigin.latitude, lng: props.searchOrigin.longitude }}>
            <MapPinIcon color={ThemeColors.TassoBrightBlue} />
          </AdvancedMarker>
        )}
        {props.locations.map((location) => (
          <DropOffLocationMapMarker
            isOpen={openLocationIndex === location.locationIndex}
            key={`${location.displayName}-${location.locationIndex}`}
            location={location}
            onClick={() => onMarkerClick(location.locationIndex)}
            {...props}
          />
        ))}
      </Map>
      {props.includeTransitionButtons && <TransitionButtons overlay />}
    </LocationMapWrapper>
  );
};

export default DropOffLocationMap;
