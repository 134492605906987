import React, { SVGProps } from 'react';

export const NotFoundIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="68" height="53" viewBox="0 0 68 53" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity="0.6">
      <path
        opacity="0.2"
        d="M38.375 1L50.25 12.875V2.25C50.25 1.91848 50.1183 1.60054 49.8839 1.36612C49.6495 1.1317 49.3315 1 49 1H38.375Z"
        fill="currentColor"
      />
      <path
        opacity="0.2"
        d="M27.125 1L40.875 14.75H29.625L17.75 2.875V2.25C17.75 1.91848 17.8817 1.60054 18.1161 1.36612C18.3505 1.1317 18.6685 1 19 1H27.125Z"
        fill="currentColor"
      />
      <path
        d="M49 1H19C18.3096 1 17.75 1.55964 17.75 2.25V13.5C17.75 14.1904 18.3096 14.75 19 14.75H49C49.6904 14.75 50.25 14.1904 50.25 13.5V2.25C50.25 1.55964 49.6904 1 49 1Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.125 1L40.875 14.75"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.375 1L50.25 12.875"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.75 2.875L29.625 14.75"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M44 14.75V21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24 14.75V21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 21.75L67 21.75" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <path d="M18 45H3L15 30V50" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M64 45H49L61 30V50" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M34 51C38.4183 51 42 46.0751 42 40C42 33.9249 38.4183 29 34 29C29.5817 29 26 33.9249 26 40C26 46.0751 29.5817 51 34 51Z"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NotFoundIcon;
