import React, { useState, useEffect, useRef } from 'react';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import CancelIcon from 'assets/CancelIcon';
import MagnifyingGlassIcon from 'assets/MagnifyingGlassIcon';
import { DropOffLocationSearchOrigin } from 'contexts/mainGlobalContext';
import { ThemeColors } from 'styles/constants';
import { AddressSearchContainer, AddressSearchInput } from './addressAutocomplete.styles';

interface props {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
  locationOverride?: DropOffLocationSearchOrigin;
}

export const USER_LOCATION_TEXT = 'Your location'; // Modeled after how Google Maps handles this.
const formatSearchValue = (value?: DropOffLocationSearchOrigin) => {
  if (!value) {
    return '';
  }

  if (value.formattedAddress) {
    return value.formattedAddress;
  }

  const dropOffAddress = localStorage.getItem('dropOffAddress');
  if (dropOffAddress) {
    return dropOffAddress;
  }

  return USER_LOCATION_TEXT;
};

const AddressAutocomplete = (props: props) => {
  const places = useMapsLibrary('places');
  const inputRef = useRef<HTMLInputElement>(null);
  const [addressAutocomplete, setAddressAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  const getSearchValue = (): string => {
    if (searchValue) {
      return searchValue;
    }

    const dropOffAddress = localStorage.getItem('dropOffAddress');
    if (dropOffAddress) {
      return dropOffAddress;
    }

    return '';
  };

  const clearSearch = () => {
    setSearchValue('');
    localStorage.setItem('dropOffAddress', '');
  };

  useEffect(() => {
    setSearchValue(formatSearchValue(props.locationOverride));
  }, [props.locationOverride]);

  useEffect(() => {
    setSearchValue(getSearchValue());
  }, [searchValue]);

  useEffect(() => {
    if (!places || !inputRef.current) {
      return;
    }

    setAddressAutocomplete(
      new places.Autocomplete(inputRef.current, {
        fields: ['geometry', 'formatted_address', 'address_components'],
      })
    );
  }, [places]);

  useEffect(() => {
    if (!addressAutocomplete) {
      return;
    }

    const eventListener = addressAutocomplete.addListener('place_changed', () => {
      props.onPlaceSelect(addressAutocomplete.getPlace());
    });

    return () => {
      eventListener?.remove();
    };
  }, [props.onPlaceSelect, addressAutocomplete]);

  return (
    <AddressSearchContainer>
      <AddressSearchInput
        ref={inputRef}
        color={
          !props.locationOverride?.formattedAddress && searchValue === USER_LOCATION_TEXT
            ? ThemeColors.TassoBrightBlue
            : ThemeColors.TassoDarkBlue
        }
        placeholder="Type your location"
        value={getSearchValue()}
        onChange={(event) => {
          setSearchValue(event.target.value);
        }}
        onFocus={() => clearSearch()}
      />
      {searchValue === '' && localStorage.getItem('dropOffAddress') === '' ? (
        <MagnifyingGlassIcon color={ThemeColors.TassoViolet} width={30} height={30} />
      ) : (
        <CancelIcon color={ThemeColors.TassoBlue} onClick={() => clearSearch()} />
      )}
    </AddressSearchContainer>
  );
};

export default AddressAutocomplete;
