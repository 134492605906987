import React, { ReactNode } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const RECAPTCHA_SECRET_KEY = import.meta.env.VITE_GOOGLE_RECAPTCHA_SECRET_KEY || '';

type DefaultProps = {
  children?: ReactNode[];
};

const RecaptchaProvider = (props: DefaultProps) => {
  const { children } = props;

  return <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SECRET_KEY}>{children}</GoogleReCaptchaProvider>;
};

export { RecaptchaProvider };
