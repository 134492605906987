import styled from 'styled-components';
import { PanelContainerStyles, TipWrapper } from 'components/GenericStyles/generic.styles';
import { DesktopWidth, TabletWidth, ThemeColors } from 'styles/constants';

export const InstructionPanelContainer = styled(PanelContainerStyles)`
  padding: 16px;

  @media (min-width: ${TabletWidth}) {
    padding: 24px;
  }
`;

export const InstructionWrapper = styled.div`
  border-top: 1px solid ${ThemeColors.CloudyGray};
  padding: 8px 0;
  margin-bottom: 16px;

  @media (min-width: ${DesktopWidth}) {
    grid-column: 3;
  }
`;

export const InstructionTitle = styled.div`
  display: flex;
  gap: 4px;
`;

export const ContentWrapper = styled.div`
  @media (min-width: ${TabletWidth}) {
    max-width: 510px;
    margin: auto;
  }

  @media (min-width: ${DesktopWidth}) {
    margin-top: 24px;
    max-width: 1000px;
    display: grid;
    grid-template-columns: 400px auto 510px;
    width: 100%;
  }
`;

export const InstructionsTipWrapper = styled(TipWrapper)`
  @media (min-width: ${DesktopWidth}) {
    grid-row: 2 / 5;
  }
`;
