import styled from 'styled-components';
import { DesktopWidth, TabletWidth, ThemeColors } from 'styles/constants';

export const LocationListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const LocationListItemDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LocationListItemLeft = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 16px;
  gap: 12px;
`;

export const LocationListItemRight = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 9px;
`;

export const LocationListItemAvailabilityWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 7px;

  @media (min-width: ${TabletWidth}) {
    svg {
      display: none;
    }
  }

  @media (min-width: ${DesktopWidth}) {
    svg {
      display: block;
    }
  }
`;

export const LocationListItemAddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LocationListItemBookmarkIconWrapper = styled.button<{ $selected?: boolean }>`
  border: none;
  background-color: ${ThemeColors.TassoWhite};
  display: flex;
  align-self: flex-end;
  width: fit-content;
  stroke: ${ThemeColors.TassoWhite};
  color: ${(props) => (props.$selected ? ThemeColors.TassoBrightBlue : ThemeColors.TassoWhite)};

  &:disabled {
    stroke: ${ThemeColors.TassoBrightBlue};
  }
`;

export const LocationListItemMapButtonWrapper = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  border: 1px solid ${ThemeColors.TassoBrightBlue};
  outline: none;
  border-radius: 40px;
  padding: 8px 12px;
  gap: 2px;
  cursor: pointer;
  text-decoration: none;
`;

export const LocationListItemDistanceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 4px;
`;
