import React, { SVGProps } from 'react';

export const RunningTimerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_5516_2165)">
      <path
        d="M17.1857 26.0355C22.7726 24.929 26.4046 19.5029 25.2981 13.916C24.1915 8.32905 18.7654 4.697 13.1785 5.80355C7.59158 6.91009 3.95953 12.3362 5.06608 17.9231C6.17263 23.51 11.5988 27.1421 17.1857 26.0355Z"
        stroke="#091F5B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.53743 5.60346L1.58746 10.0106"
        stroke="#091F5B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0908 2.3252L25.4979 5.27516"
        stroke="#091F5B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9072 9.48242L15.1822 15.9199L21.6197 14.6449"
        stroke="#091F5B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5516_2165">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default RunningTimerIcon;
