import React, { ReactNode } from 'react';
import { APIProvider } from '@vis.gl/react-google-maps';

export const RECAPTCHA_SECRET_KEY = import.meta.env.VITE_GOOGLE_RECAPTCHA_SECRET_KEY || '';

type DefaultProps = {
  children?: ReactNode[];
};

const GoogleApiProvider = (props: DefaultProps) => {
  const { children } = props;

  return <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>{children}</APIProvider>;
};

export { GoogleApiProvider };
