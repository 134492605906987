import styled from 'styled-components';

export const TabletWidth = '768px';
export const DesktopWidth = '1024px';

export const ThemeColors = {
  TassoBlue: '#091F5B',
  TassoDarkBlue: '#031137',
  TassoBrightBlue: '#5067EB',
  TassoViolet: '#95A4FF',
  TassoLightViolet: '#DFE4FF',
  TassoHeritageRed: '#EA5153',
  TassoWhite: '#FFFFFF',
  OffWhite: '#F9F9F9',
  LightGray: '#F0F0F0',
  CloudyGray: '#D2D2D2',
  DarkGray: '#999D9E',
  HeavyGray: '#545557',
  TassoBlack: '#000000',
};

export interface DisplayProps {
  color?: string;
  margin?: string;
}

export interface FontProps {
  color?: string;
}

export const ThemeFonts = {
  DisplayLarge: styled.div<DisplayProps>`
    font-size: 57px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -1px;
    ${(p) => p.color && `color: ${p.color};`}
    ${(p) => p.margin && `margin: ${p.margin};`}

    @media (min-width: ${TabletWidth}) {
      font-size: 71px;
      line-height: 75px;
      letter-spacing: -1px;
    }
  `,

  DisplayMedium: styled.div<DisplayProps>`
    font-size: 45px;
    font-weight: 600;
    line-height: 47px;
    letter-spacing: -1px;
    ${(p) => p.color && `color: ${p.color};`}
    ${(p) => p.margin && `margin: ${p.margin};`}

    @media (min-width: ${TabletWidth}) {
      font-size: 56px;
      line-height: 59px;
      letter-spacing: -1px;
    }
  `,

  DisplaySmall: styled.div<DisplayProps>`
    font-size: 36px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: -1px;
    ${(p) => p.color && `color: ${p.color};`}
    ${(p) => p.margin && `margin: ${p.margin};`}

    @media (min-width: ${TabletWidth}) {
      font-size: 45px;
      line-height: 48px;
      letter-spacing: -1px;
    }
  `,

  HeadlineLarge: styled.div<FontProps>`
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
    ${(p) => p.color && `color: ${p.color};`}

    @media (min-width: ${TabletWidth}) {
      font-size: 40px;
      line-height: 45px;
    }
  `,

  HeadlineMedium: styled.div<FontProps>`
    font-size: 28px;
    font-weight: 500;
    line-height: 30px;
    ${(p) => p.color && `color: ${p.color};`}

    @media (min-width: ${TabletWidth}) {
      font-size: 35px;
      line-height: 38px;
    }
  `,

  HeadlineSmall: styled.div<FontProps>`
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    ${(p) => p.color && `color: ${p.color};`}

    @media (min-width: ${TabletWidth}) {
      font-size: 30px;
      line-height: 35px;
    }
  `,

  TitleLarge: styled.div<FontProps>`
    font-size: 22px;
    line-height: 28px;
    ${(p) => p.color && `color: ${p.color};`}

    @media (min-width: ${TabletWidth}) {
      font-size: 28px;
      line-height: 35px;
    }
  `,

  TitleMedium: styled.div<FontProps>`
    font-size: 16px;
    line-height: 20px;
    ${(p) => p.color && `color: ${p.color};`}

    @media (min-width: ${TabletWidth}) {
      font-size: 20px;
      line-height: 25px;
    }
  `,

  TitleSmall: styled.div<FontProps>`
    font-size: 14px;
    line-height: 18px;
    ${(p) => p.color && `color: ${p.color};`}

    @media (min-width: ${TabletWidth}) {
      font-size: 18px;
      line-height: 23px;
    }
  `,

  LabelLarge: styled.div<FontProps>`
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    ${(p) => p.color && `color: ${p.color};`}

    @media (min-width: ${TabletWidth}) {
      font-size: 20px;
      line-height: 25px;
    }
  `,

  LabelMedium: styled.div<FontProps>`
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    ${(p) => p.color && `color: ${p.color};`}

    @media (min-width: ${TabletWidth}) {
      font-size: 18px;
      line-height: 25px;
    }
  `,

  LabelSmall: styled.div<FontProps>`
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    ${(p) => p.color && `color: ${p.color};`}

    @media (min-width: ${TabletWidth}) {
      font-size: 15px;
      line-height: 20px;
    }
  `,

  BodyLarge: styled.div<FontProps>`
    font-size: 16px;
    line-height: 20px;
    ${(p) => p.color && `color: ${p.color};`}

    @media (min-width: ${TabletWidth}) {
      font-size: 20px;
      line-height: 25px;
    }
  `,

  BodyMedium: styled.div<FontProps>`
    font-size: 14px;
    line-height: 20px;
    ${(p) => p.color && `color: ${p.color};`}

    @media (min-width: ${TabletWidth}) {
      font-size: 18px;
      line-height: 25px;
    }
  `,

  BodySmall: styled.div<FontProps>`
    font-size: 12px;
    line-height: 16px;
    ${(p) => p.color && `color: ${p.color};`}

    @media (min-width: ${TabletWidth}) {
      font-size: 15px;
      line-height: 20px;
    }
  `,
};
