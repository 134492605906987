import React from 'react';

import { InfoWindow, AdvancedMarker, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import MapPinIcon from 'assets/MapPinIcon';
import DropOffLocation, { LocationProps } from 'components/MapPanel/DropOffLocation';
import { ThemeColors } from 'styles/constants';
import { CarrierLocation } from '../../types';

interface props extends LocationProps {
  location: CarrierLocation;
  initialDisplayInfo?: boolean;
  isOpen: boolean;
  onClick: (locationId: number) => void;
}

const DropOffLocationMapMarker = (props: props) => {
  const [markerRef, marker] = useAdvancedMarkerRef();

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        clickable
        position={{ lat: props.location.latitude, lng: props.location.longitude }}
        onClick={() => props.onClick(props.location.locationIndex)}
      >
        <MapPinIcon color={ThemeColors.TassoHeritageRed} />
      </AdvancedMarker>
      {props.isOpen && (
        <InfoWindow
          headerDisabled
          anchor={marker}
          onCloseClick={() => props.onClick(props.location.locationIndex)}
          maxWidth={360}
          minWidth={360}
        >
          <DropOffLocation {...props} />
        </InfoWindow>
      )}
    </>
  );
};

export default DropOffLocationMapMarker;
