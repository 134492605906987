import { Entry } from 'contentful';
import {
  TypeExperienceSkeleton,
  isTypeLocationPanel,
  isTypeMapPanel,
  isTypeInstructionPanel,
  isTypeVideo,
} from 'contentmodels';
import { contentfulClient } from 'utils/apis/contentfulClient';
import { Extras, Panel, Phases, RawPanel } from '../constants/baseExperienceTypes';
export const CONTENTFUL_BASE_EXPERIENCE_ID = '2shPGfqpTw35Y3CatPsLGg';

export const getRawResponse = async (
  shortCode: string
): Promise<Entry<TypeExperienceSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>> => {
  const results = await contentfulClient.withoutUnresolvableLinks.getEntries<TypeExperienceSkeleton>({
    'fields.shortCode': shortCode,
    content_type: 'experience',
    include: 10,
  });

  return results.items[0];
};

export const getParsedJsonExperience = async (
  shortCode: string,
  callback: (result: { panels: Panel[]; extras: Extras }) => void
): Promise<void> => {
  const phases: Phases[] = [];
  const result: Panel[] = [];

  try {
    const rawResponse = await getRawResponse(shortCode);
    rawResponse.fields.introPanels?.forEach((panel) => {
      if (!panel) return;
      result.push({
        ...panel,
        // These fields are not relevant to intro panels - these are just placeholders to make typescript happy.
        phaseName: 'intro',
        panelNumber: 0,
        totalPanels: 0,
      });
    });

    rawResponse.fields.phases.forEach((phase) => {
      if (!phase) {
        return;
      }
      phases.push(phase.fields.titleText as Phases);
      result.push({
        ...phase,
        // These fields are not relevant to phase panels - these are just placeholders to make typescript happy.
        phaseName: phase.fields.titleText,
        panelNumber: 0,
        totalPanels: 0,
      });
      let panelCount = 1;

      // This will check all panel types we need to consider when calculating
      // the progress bar total number and the current panel number for the progress bar
      // note: not all panel types are used intentionally in the calculation per the design spec
      const totalPanels = phase.fields.steps.filter((step) => isPanelProgressBarCompatible(step)).length;

      phase.fields.steps.map((value) => {
        if (value) {
          result.push({
            ...value,
            phaseName: phase.fields.titleText,
            panelNumber: panelCount,
            totalPanels: totalPanels,
          });
          if (isPanelProgressBarCompatible(value)) {
            panelCount += 1;
          }
        }
      });
    });

    callback({ panels: result, extras: { phases: phases } });
  } catch (ex: any) {
    // if API call fails, return empty array and show 503
    console.log('Error: ', JSON.stringify(ex));
    callback({ panels: result, extras: { phases: phases } });
    return;
  }
};

export const isPanelProgressBarCompatible = (panel?: RawPanel): boolean => {
  if (!panel) {
    return false;
  }

  if (isTypeVideo(panel)) {
    return true;
  }

  if (isTypeLocationPanel(panel)) {
    return true;
  }

  if (isTypeMapPanel(panel) && panel.fields.includeProgressBar) {
    return true;
  }

  if (isTypeInstructionPanel(panel)) {
    return true;
  }

  return false;
};
