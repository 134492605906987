import styled from 'styled-components';
import { PanelContainerStyles } from 'components/GenericStyles/generic.styles';
import { DesktopWidth, TabletWidth, ThemeColors, ThemeFonts } from 'styles/constants';

export const OverviewPanelContainer = styled(PanelContainerStyles)`
  padding: 16px;
  justify-content: inherit;

  @media (min-width: ${TabletWidth}) {
    padding: 24px;
  }
`;

export const ContentWrapper = styled.div`
  @media (min-width: ${TabletWidth}) {
    width: 510px;
    margin: 0 auto;
    flex: grow;
  }
`;

export const TitleText = styled(ThemeFonts.DisplayMedium)`
  margin: 4px 0 12px 0;
  color: ${ThemeColors.TassoBlue};

  @media (min-width: ${TabletWidth}) {
    margin-top: 80px;
  }

  @media (min-width: ${DesktopWidth}) {
    margin-top: 56px;
  }
`;

export const OverviewStepWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${ThemeColors.CloudyGray};
  padding: 8px 0;
  margin-bottom: 48px;
`;

export const OverviewStepContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 8px;
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  align-items: flex-start;
`;
