import React, { SVGProps } from 'react';

export const SadFaceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1085_6285)">
      <path
        d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
        stroke="#EA5153"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M7.1875 9.375C7.70527 9.375 8.125 8.95527 8.125 8.4375C8.125 7.91973 7.70527 7.5 7.1875 7.5C6.66973 7.5 6.25 7.91973 6.25 8.4375C6.25 8.95527 6.66973 9.375 7.1875 9.375Z"
        fill="#EA5153"
      />
      <path
        d="M12.8125 9.375C13.3303 9.375 13.75 8.95527 13.75 8.4375C13.75 7.91973 13.3303 7.5 12.8125 7.5C12.2947 7.5 11.875 7.91973 11.875 8.4375C11.875 8.95527 12.2947 9.375 12.8125 9.375Z"
        fill="#EA5153"
      />
      <path
        d="M6.25 13.125C7.1875 13.125 7.1875 11.875 8.125 11.875C9.0625 11.875 9.0625 13.125 10 13.125C10.9375 13.125 10.9375 11.875 11.875 11.875C12.8125 11.875 12.8125 13.125 13.75 13.125"
        stroke="#EA5153"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1085_6285">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SadFaceIcon;
