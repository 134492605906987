import styled from 'styled-components';
import { TabletWidth } from 'styles/constants';

export const ErrorBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  margin: 24% 16px;
  gap: 40px;

  @media (min-width: ${TabletWidth}) {
    max-width: 600px;
    margin: 16% auto;
  }
`;
