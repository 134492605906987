import styled from 'styled-components';
import { PanelContainerStyles } from 'components/GenericStyles/generic.styles';
import { DesktopWidth, TabletWidth } from 'styles/constants';

export const InfoPanelContainerStyles = styled(PanelContainerStyles)`
  padding: 16px;
  justify-content: flex-start;

  @media (min-width: ${TabletWidth}) {
    padding: 24px;
    justify-content: space-between;
  }
`;

export const BodyWrapper = styled.div`
  margin-top: 15%;

  @media (min-width: ${TabletWidth}) {
    margin-top: unset;
  }

  @media (min-width: ${DesktopWidth}) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 80px;
  }
`;

// Center icon
export const BackgroundIconStyles = styled.div`
  display: flex;
  justify-content: end;
  margin-right: -30%;

  @media (min-width: ${TabletWidth}) {
    margin-right: 0;
  }

  @media (min-width: ${DesktopWidth}) {
    height: 524px;
  }
`;

export const ContentWrapper = styled.div`
  @media (min-width: ${TabletWidth}) {
    max-width: 540px;
    margin-left: 56px;
  }

  @media (min-width: ${DesktopWidth}) {
    max-width: 665px;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  position: fixed;
  bottom: 16px;
  width: 92%;

  @media (min-width: ${TabletWidth}) {
    position: unset;
    bottom: unset;
    width: unset;
  }
`;
