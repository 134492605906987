import styled from 'styled-components';
import { IconButton, SecondaryButtonStyles } from 'components/GenericStyles/generic.styles';
import { DesktopWidth, TabletWidth, ThemeColors } from 'styles/constants';

interface HelpPageProps {
  color: string;
}
// Center icon
export const HelpWrapper = styled.div<HelpPageProps>`
  padding: 16px;
  color: ${(p) => p.color};
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  @media (min-width: ${TabletWidth}) {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 24px 24px 0 24px;
  }
`;

export const HelpHeader = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const TipBackground = styled.div`
  background-color: ${ThemeColors.CloudyGray};
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
`;

export const QABlock = styled.div`
  border-bottom: 1px solid ${ThemeColors.CloudyGray};
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SafeNeedleLink = styled.a`
  color: ${ThemeColors.TassoDarkBlue};
`;

export const HelpContent = styled.div`
  @media (min-width: ${TabletWidth}) {
    width: 510px;
  }

  @media (min-width: ${DesktopWidth}) {
    display: flex;
    gap: 76px;
    width: 1024px;
  }
`;

export const CloseButtonIcon = styled(IconButton)`
  @media (min-width: ${TabletWidth}) {
    display: none;
  }
`;

export const CloseButtonContainer = styled.div`
  display: none;

  @media (min-width: ${TabletWidth}) {
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    bottom: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.6);
    height: 200px;
    padding: 24px;
  }
`;

export const CloseButton = styled(SecondaryButtonStyles)`
  display: none;

  @media (min-width: ${TabletWidth}) {
    display: block;
  }
`;
