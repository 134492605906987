import React, { SVGProps } from 'react';

export const MapPinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="40" height="47" viewBox="0 0 40 47" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_d_6539_2198)">
      <path
        d="M20.3216 0C16.2595 0.0046082 12.365 1.62032 9.49268 4.49268C6.62032 7.36503 5.00461 11.2595 5 15.3216C5 28.432 18.9287 38.3336 19.5224 38.7479C19.7566 38.912 20.0356 39 20.3216 39C20.6075 39 20.8865 38.912 21.1207 38.7479C21.7145 38.3336 35.6432 28.432 35.6432 15.3216C35.6386 11.2595 34.0229 7.36503 31.1505 4.49268C28.2781 1.62032 24.3837 0.0046082 20.3216 0ZM20.3216 9.7501C21.4235 9.7501 22.5007 10.0769 23.4169 10.6891C24.3332 11.3013 25.0473 12.1714 25.469 13.1895C25.8907 14.2075 26.001 15.3278 25.786 16.4085C25.571 17.4893 25.0404 18.482 24.2612 19.2612C23.482 20.0404 22.4893 20.571 21.4085 20.786C20.3278 21.001 19.2075 20.8907 18.1895 20.469C17.1714 20.0473 16.3013 19.3332 15.6891 18.4169C15.0769 17.5007 14.7501 16.4235 14.7501 15.3216C14.7501 13.8439 15.3371 12.4268 16.382 11.3819C17.4268 10.3371 18.8439 9.7501 20.3216 9.7501Z"
        fill="currentColor"
      />
      <path
        d="M19.8093 38.3384L19.8086 38.3379C19.5296 38.1432 15.9368 35.5921 12.4189 31.4711C8.89395 27.342 5.5001 21.7025 5.5 15.3219C5.50453 11.3923 7.06757 7.62489 9.84623 4.84623C12.6248 2.06764 16.3921 0.504608 20.3216 0.5C24.2511 0.504608 28.0183 2.06764 30.7969 4.84623C33.5757 7.62496 35.1387 11.3924 35.1432 15.3222C35.143 21.7027 31.7492 27.3421 28.2243 31.4711C24.7064 35.5921 21.1136 38.1432 20.8346 38.3379L20.8339 38.3384C20.6837 38.4436 20.5049 38.5 20.3216 38.5C20.1383 38.5 19.9594 38.4436 19.8093 38.3384ZM23.6947 10.2733C22.6963 9.60618 21.5224 9.2501 20.3216 9.2501C18.7113 9.2501 17.167 9.88977 16.0284 11.0284C14.8898 12.167 14.2501 13.7113 14.2501 15.3216C14.2501 16.5224 14.6062 17.6963 15.2733 18.6947C15.9405 19.6932 16.8887 20.4714 17.9981 20.9309C19.1075 21.3904 20.3283 21.5107 21.5061 21.2764C22.6838 21.0421 23.7657 20.4639 24.6148 19.6148C25.4639 18.7657 26.0421 17.6838 26.2764 16.5061C26.5107 15.3283 26.3904 14.1075 25.9309 12.9981C25.4714 11.8887 24.6932 10.9405 23.6947 10.2733Z"
        stroke="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_6539_2198"
        x="1"
        y="0"
        width="38.6432"
        height="47"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6539_2198" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6539_2198" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default MapPinIcon;
