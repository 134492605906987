import React from 'react';

import { Entry } from 'contentful';
import { TipIconStyles, TipIconWrapper } from 'components/GenericStyles/generic.styles';
import { TypeTipsSkeleton } from 'contentmodels';
import { ThemeFonts } from 'styles/constants';
import { VideoTipWrapper } from '../videoPanel.styles';

const VideoTips = (props: { tip: Entry<TypeTipsSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string> | undefined }) => {
  const { tip } = props;
  return (
    <VideoTipWrapper>
      {tip?.fields.titleIconPosition !== undefined && (
        <TipIconWrapper $titleIconPosition={tip.fields.titleIconPosition}>
          {tip.fields.titleText && <ThemeFonts.LabelLarge>{tip.fields.titleText}</ThemeFonts.LabelLarge>}
          <TipIconStyles $titleIconPosition={tip.fields.titleIconPosition}>
            {tip.fields.titleIcon?.fields.file?.url && tip.fields.titleIcon.fields.description && (
              <img src={tip.fields.titleIcon?.fields.file?.url} alt={tip.fields.titleIcon.fields.description} />
            )}
          </TipIconStyles>
        </TipIconWrapper>
      )}
      {tip?.fields.textBody && <ThemeFonts.BodyMedium>{tip.fields.textBody}</ThemeFonts.BodyMedium>}
    </VideoTipWrapper>
  );
};

export default VideoTips;
