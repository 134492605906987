import styled from 'styled-components';
import { SecondaryButtonStyles } from 'components/GenericStyles/generic.styles';
import { TabletWidth } from 'styles/constants';

export const NextButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
`;

export const NextButtonStyles = styled(SecondaryButtonStyles)`
  width: 100%;

  @media (min-width: ${TabletWidth}) {
    padding: 0 32px;
    width: fit-content;
  }
`;
