import styled from 'styled-components';
import { PanelContainerStyles } from 'components/GenericStyles/generic.styles';
import { TabletWidth, ThemeColors } from 'styles/constants';

export const RegistrationPanelContainer = styled(PanelContainerStyles)`
  padding: 16px;

  @media (min-width: ${TabletWidth}) {
    padding: 24px;
  }
`;

export const RegistrationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-evenly;
  margin-bottom: 64px;

  @media (min-width: ${TabletWidth}) {
    align-items: center;
    margin: auto;
    max-width: 510px;
  }
`;

export const CodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  gap: 12px;
`;

export const InputWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const InputDash = styled.div`
  border: 1px solid ${ThemeColors.TassoBlue};
  width: 25px;
`;

export const CodeInput = styled.input`
  border: 1px solid ${ThemeColors.CloudyGray};
  width: 136px;
  height: 56px;
  border-radius: 6px;
  font-size: 40px;
  letter-spacing: 6px;
  padding: 2px 8px;
  text-transform: uppercase;
  text-align: center;
  color: ${ThemeColors.TassoBlue};

  &:focus {
    outline: 2px solid ${ThemeColors.TassoBrightBlue};
  }

  @media (min-width: ${TabletWidth}) {
    width: 220px;
    height: 100px;
    font-size: 64px;
  }
`;

export const ErrorWrapper = styled.div`
  display: flex;
  gap: 4px;
  color: ${ThemeColors.TassoHeritageRed};
`;
