import React, { SVGProps } from 'react';

export const ReplayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_5458_8280)">
      <path
        d="M24.4991 5.24746V10.4975C24.4991 10.7295 24.4069 10.9521 24.2428 11.1162C24.0787 11.2803 23.8562 11.3725 23.6242 11.3725H18.3745C18.2014 11.3726 18.0321 11.3214 17.8881 11.2252C17.744 11.1291 17.6318 10.9924 17.5655 10.8324C17.4992 10.6724 17.4819 10.4964 17.5157 10.3266C17.5495 10.1567 17.633 10.0008 17.7555 9.8784L19.758 7.87246C18.1471 6.3289 16.0044 5.46385 13.7734 5.45637H13.7242C11.4381 5.45189 9.24222 6.34775 7.61162 7.95012C7.44453 8.10611 7.22303 8.19051 6.99451 8.18527C6.76599 8.18003 6.54859 8.08556 6.38883 7.92207C6.22906 7.75858 6.13962 7.53905 6.13963 7.31045C6.13964 7.08186 6.22911 6.86234 6.38889 6.69887C8.33933 4.79309 10.9547 3.72096 13.6815 3.70932C16.4083 3.69769 19.0327 4.74747 20.9993 6.63652L23.0073 4.6284C23.1298 4.50664 23.2856 4.42385 23.455 4.39046C23.6245 4.35707 23.8 4.37458 23.9595 4.44077C24.119 4.50697 24.2554 4.61889 24.3514 4.76244C24.4474 4.90599 24.4988 5.07475 24.4991 5.24746ZM20.388 20.0448C18.7673 21.6278 16.5958 22.5205 14.3304 22.535C12.0651 22.5495 9.88235 21.6846 8.24158 20.1225L10.2441 18.1198C10.3677 17.9976 10.4522 17.8413 10.4866 17.671C10.5211 17.5006 10.5041 17.3238 10.4376 17.1631C10.3712 17.0025 10.2585 16.8653 10.1138 16.769C9.96903 16.6727 9.79891 16.6217 9.62508 16.6225H4.37543C4.14338 16.6225 3.92084 16.7146 3.75675 16.8787C3.59267 17.0428 3.50049 17.2654 3.50049 17.4975V22.7475C3.50035 22.9206 3.55159 23.0899 3.64772 23.2339C3.74385 23.378 3.88055 23.4902 4.04051 23.5565C4.20047 23.6228 4.3765 23.6401 4.54631 23.6063C4.71613 23.5725 4.87209 23.489 4.99445 23.3665L7.00026 21.3584C8.93847 23.2291 11.5249 24.2778 14.2185 24.2853H14.2765C17.0195 24.2923 19.6549 23.2184 21.6118 21.296C21.7716 21.1326 21.861 20.9131 21.861 20.6845C21.8611 20.4559 21.7716 20.2363 21.6119 20.0729C21.4521 19.9094 21.2347 19.8149 21.0062 19.8096C20.7776 19.8044 20.5561 19.8888 20.3891 20.0448H20.388Z"
        fill="#091F5B"
      />
    </g>
    <defs>
      <clipPath id="clip0_5458_8280">
        <rect width="27.9981" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ReplayIcon;
