import React from 'react';

import Dialog from '@mui/material/Dialog';
import CancelIcon from 'assets/CancelIcon';
import { ThemeColors, ThemeFonts } from 'styles/constants';
import {
  CloseButton,
  CloseButtonContainer,
  CloseButtonIcon,
  HelpContent,
  HelpHeader,
  HelpWrapper,
  QABlock,
  SafeNeedleLink,
  TipBackground,
} from './helpPage.styles';

interface HelpModalProps {
  open: boolean;
  onClose: () => void;
}

const HelpPage = (props: HelpModalProps) => {
  return (
    <Dialog fullScreen open={props.open} onClose={props.onClose}>
      <HelpWrapper color={ThemeColors.TassoDarkBlue}>
        <HelpContent>
          <div>
            <HelpHeader>
              <ThemeFonts.DisplayMedium color={ThemeColors.TassoBlue}>
                Have questions? We’ve got you covered.
              </ThemeFonts.DisplayMedium>
              <CloseButtonIcon onClick={props.onClose}>
                <CancelIcon width={48} height={48} color={ThemeColors.DarkGray} />
              </CloseButtonIcon>
            </HelpHeader>
            <TipBackground>
              <ThemeFonts.LabelLarge>If you need help using a Tasso device, please call us at:</ThemeFonts.LabelLarge>
              <ThemeFonts.DisplaySmall>1-800-257-2370</ThemeFonts.DisplaySmall>
            </TipBackground>
          </div>
          <div>
            <QABlock>
              <ThemeFonts.LabelLarge>Can I use this on another location instead of my arm?</ThemeFonts.LabelLarge>
              <ThemeFonts.BodyLarge>
                Please contact your principal investigator or study coordinator.
              </ThemeFonts.BodyLarge>
            </QABlock>
            <QABlock>
              <ThemeFonts.LabelLarge>How can I dispose of the device I just used?</ThemeFonts.LabelLarge>
              <ThemeFonts.BodyLarge>
                Generally the used device can be safely be disposed of in household waste, though please refer to{' '}
                <SafeNeedleLink href="https://safeneedledisposal.org/" target="_blank" rel="noreferrer">
                  Safe Needle Disposal Info (opens in a new tab)
                </SafeNeedleLink>{' '}
                for more information.
              </ThemeFonts.BodyLarge>
            </QABlock>
            <QABlock>
              <ThemeFonts.LabelLarge>I only filled up the tube with a few drops, is that enough?</ThemeFonts.LabelLarge>
              <ThemeFonts.BodyLarge>
                That’s okay! Please send in your blood sample anyway! If there isn’t enough in your sample, the study
                coordinators will reach out to you.
              </ThemeFonts.BodyLarge>
            </QABlock>
          </div>
        </HelpContent>
        <CloseButtonContainer>
          <CloseButton onClick={props.onClose}>Close</CloseButton>
        </CloseButtonContainer>
      </HelpWrapper>
    </Dialog>
  );
};

export default HelpPage;
