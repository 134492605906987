import styled from 'styled-components';
import { TabletWidth } from 'styles/constants';

export const FooterContainerFixed = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  align-items: center;

  @media (min-width: ${TabletWidth}) {
    padding: 64px 0;
    background: rgba(255, 255, 255, 0.6);
  }
`;

export const FooterContainerFlex = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`;
