import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful';
import type { TypeInfoTextPanelSkeleton } from './TypeInfoTextPanel';
import type { TypeInstructionPanelSkeleton } from './TypeInstructionPanel';
import type { TypeLocationPanelSkeleton } from './TypeLocationPanel';
import type { TypeMapPanelSkeleton } from './TypeMapPanel';
import type { TypePhaseSummarySkeleton } from './TypePhaseSummary';
import type { TypeVideoSkeleton } from './TypeVideo';

export interface TypePhaseFields {
  name: EntryFieldTypes.Symbol;
  titleText: EntryFieldTypes.Text;
  subText?: EntryFieldTypes.Text;
  tertiaryText?: EntryFieldTypes.Text;
  swipeText?: EntryFieldTypes.Text;
  nextIcon?: EntryFieldTypes.AssetLink;
  timeText?: EntryFieldTypes.Text;
  phaseSummary?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypePhaseSummarySkeleton>>;
  steps: EntryFieldTypes.Array<
    EntryFieldTypes.EntryLink<
      | TypeInfoTextPanelSkeleton
      | TypeInstructionPanelSkeleton
      | TypeLocationPanelSkeleton
      | TypeMapPanelSkeleton
      | TypeVideoSkeleton
    >
  >;
  helpIcon: EntryFieldTypes.AssetLink;
  nextButtonText: EntryFieldTypes.Symbol;
}

export type TypePhaseSkeleton = EntrySkeletonType<TypePhaseFields, 'phase'>;
export type TypePhase<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<
  TypePhaseSkeleton,
  Modifiers,
  Locales
>;

export function isTypePhase<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypePhase<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'phase';
}

export type TypePhaseWithoutLinkResolutionResponse = TypePhase<'WITHOUT_LINK_RESOLUTION'>;
export type TypePhaseWithoutUnresolvableLinksResponse = TypePhase<'WITHOUT_UNRESOLVABLE_LINKS'>;
export type TypePhaseWithAllLocalesResponse<Locales extends LocaleCode = LocaleCode> = TypePhase<
  'WITH_ALL_LOCALES',
  Locales
>;
export type TypePhaseWithAllLocalesAndWithoutLinkResolutionResponse<Locales extends LocaleCode = LocaleCode> =
  TypePhase<'WITHOUT_LINK_RESOLUTION' | 'WITH_ALL_LOCALES', Locales>;
export type TypePhaseWithAllLocalesAndWithoutUnresolvableLinksResponse<Locales extends LocaleCode = LocaleCode> =
  TypePhase<'WITHOUT_UNRESOLVABLE_LINKS' | 'WITH_ALL_LOCALES', Locales>;
