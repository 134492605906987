import React, { ReactNode } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useSwiper } from 'swiper/react';
import NotFoundIcon from 'assets/NotFound';
import ServiceErrorIcon from 'assets/ServiceErrorIcon';
import ServiceUnavailableIcon from 'assets/ServiceUnavailableIcon';
import { PanelContainerStyles, PrimaryButtonStyles } from 'components/GenericStyles/generic.styles';
import HelpButton from 'components/SharedComponents/HelpButton';
import { ThemeColors, ThemeFonts } from 'styles/constants';
import { ErrorBodyWrapper } from './errorPanel.styles';

const ErrorPanel = (props: { errorCode: string; openHelpModal: () => void }) => {
  const { errorCode } = props;
  const swiper = useSwiper();

  let icon: ReactNode;
  let text: string;
  let actionElement: ReactNode | null = null;

  switch (errorCode) {
    case '503': {
      icon = <ServiceUnavailableIcon style={{ color: ThemeColors.TassoBrightBlue }} />;
      text = 'The service you requested is not available at this time';
      break;
    }
    case '404': {
      icon = <NotFoundIcon style={{ color: ThemeColors.TassoBrightBlue }} />;
      text = "The page you're looking for doesn't exist or has been moved";
      actionElement = <ThemeFonts.TitleLarge>Take a moment to double check your URL</ThemeFonts.TitleLarge>;
      break;
    }
    case '500': {
      icon = <ServiceErrorIcon style={{ color: ThemeColors.TassoBrightBlue }} />;
      text = 'An error has occurred. Please try again from the beginning.';
      actionElement = (
        <PrimaryButtonStyles onClick={() => swiper.slideTo(0)}>
          <ThemeFonts.LabelLarge>Restart</ThemeFonts.LabelLarge>
        </PrimaryButtonStyles>
      );
      break;
    }
    default: {
      throw new Error(`Unsupported error code: ${errorCode}.`);
    }
  }

  return (
    <PanelContainerStyles padding="8px 16px" $justifyContent="flex-start">
      <HelpButton {...props} />
      <ErrorBodyWrapper>
        {icon}
        <ThemeFonts.DisplayMedium>{text}</ThemeFonts.DisplayMedium>
        {actionElement}
      </ErrorBodyWrapper>
    </PanelContainerStyles>
  );
};

export default ErrorPanel;
