import React from 'react';
import HelpButton from 'components/SharedComponents/HelpButton';
import { ThemeFonts } from 'styles/constants';
import { LoadingPanelContainerStyles, LoadingPanelWrapper, LoadingWheel } from './loadingPanel.styles';

const LoadingPanel = (props: { openHelpModal: () => void }) => {
  return (
    <LoadingPanelContainerStyles>
      <HelpButton {...props} />
      <LoadingPanelWrapper>
        <LoadingWheel />
        <ThemeFonts.TitleLarge style={{ marginTop: '32px' }}>Loading</ThemeFonts.TitleLarge>
      </LoadingPanelWrapper>
    </LoadingPanelContainerStyles>
  );
};

export default LoadingPanel;
