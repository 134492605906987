import React, { FC } from 'react';
import { GlobalStoreProvider } from 'contexts/mainGlobalContext';
import { GoogleApiProvider } from './GoogleApi';
import { RecaptchaProvider } from './Recaptcha';

const providers = [GoogleApiProvider, RecaptchaProvider, GlobalStoreProvider];

const AppProviders: FC<any> = ({ children }) =>
  providers.reduce((acc, Provider) => <Provider>{acc}</Provider>, children);

export default AppProviders;
