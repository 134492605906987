import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful';

export interface TypeMapPanelFields {
  name: EntryFieldTypes.Symbol;
  helpIcon: EntryFieldTypes.AssetLink;
  assignableBookmarks: EntryFieldTypes.Boolean;
  includeGoogleMapsLink: EntryFieldTypes.Boolean;
  includeProgressBar: EntryFieldTypes.Boolean;
  includeTransitionButtons: EntryFieldTypes.Boolean;
  includeBackButton: EntryFieldTypes.Boolean;
}

export type TypeMapPanelSkeleton = EntrySkeletonType<TypeMapPanelFields, 'mapPanel'>;
export type TypeMapPanel<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<
  TypeMapPanelSkeleton,
  Modifiers,
  Locales
>;

export function isTypeMapPanel<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeMapPanel<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'mapPanel';
}

export type TypeMapPanelWithoutLinkResolutionResponse = TypeMapPanel<'WITHOUT_LINK_RESOLUTION'>;
export type TypeMapPanelWithoutUnresolvableLinksResponse = TypeMapPanel<'WITHOUT_UNRESOLVABLE_LINKS'>;
export type TypeMapPanelWithAllLocalesResponse<Locales extends LocaleCode = LocaleCode> = TypeMapPanel<
  'WITH_ALL_LOCALES',
  Locales
>;
export type TypeMapPanelWithAllLocalesAndWithoutLinkResolutionResponse<Locales extends LocaleCode = LocaleCode> =
  TypeMapPanel<'WITHOUT_LINK_RESOLUTION' | 'WITH_ALL_LOCALES', Locales>;
export type TypeMapPanelWithAllLocalesAndWithoutUnresolvableLinksResponse<Locales extends LocaleCode = LocaleCode> =
  TypeMapPanel<'WITHOUT_UNRESOLVABLE_LINKS' | 'WITH_ALL_LOCALES', Locales>;
