import React, { SVGProps } from 'react';

export const BookmarkStaticIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_5179_9438)">
      <path
        d="M23 25.5L18 22.375L13 25.5V11.75C13 11.5842 13.0658 11.4253 13.1831 11.3081C13.3003 11.1908 13.4592 11.125 13.625 11.125H22.375C22.5408 11.125 22.6997 11.1908 22.8169 11.3081C22.9342 11.4253 23 11.5842 23 11.75V25.5Z"
        stroke="currentColor"
        fill="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5179_9438">
        <rect width="20" height="20" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
);

export default BookmarkStaticIcon;
