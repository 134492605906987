import React, { SVGProps } from 'react';

export const MagnifyingGlassIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M26.7469 26.3048L20.7258 20.2837C22.4428 18.3092 23.3261 15.7451 23.1894 13.132C23.0528 10.5189 21.9069 8.0609 19.9933 6.27618C18.0797 4.49147 15.548 3.51944 12.9317 3.56501C10.3154 3.61058 7.81901 4.67017 5.96874 6.52044C4.11848 8.37071 3.05888 10.8671 3.01331 13.4834C2.96775 16.0997 3.93977 18.6314 5.72449 20.545C7.5092 22.4586 9.96718 23.6045 12.5803 23.7411C15.1934 23.8778 17.7575 22.9945 19.732 21.2775L25.7531 27.2986C25.8864 27.4228 26.0627 27.4904 26.2449 27.4872C26.427 27.484 26.6008 27.4102 26.7297 27.2813C26.8585 27.1525 26.9323 26.9787 26.9355 26.7966C26.9387 26.6144 26.8711 26.4381 26.7469 26.3048ZM4.45313 13.6767C4.45313 11.9616 4.96173 10.2849 5.91461 8.85887C6.86748 7.43278 8.22184 6.32129 9.80642 5.66493C11.391 5.00858 13.1346 4.83685 14.8168 5.17146C16.499 5.50606 18.0442 6.33198 19.2569 7.54476C20.4697 8.75754 21.2956 10.3027 21.6303 11.9849C21.9649 13.6671 21.7931 15.4107 21.1368 16.9953C20.4804 18.5799 19.3689 19.9342 17.9428 20.8871C16.5168 21.84 14.8401 22.3486 13.125 22.3486C10.8259 22.3458 8.62183 21.4312 6.99615 19.8056C5.37046 18.1799 4.45592 15.9758 4.45313 13.6767Z"
      fill="currentColor"
    />
  </svg>
);

export default MagnifyingGlassIcon;
