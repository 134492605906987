import React, { SVGProps } from 'react';
import { ThemeColors } from 'styles/constants';

export const TimeClockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path
        d="M6.5 11.376C9.19239 11.376 11.375 9.19336 11.375 6.50098C11.375 3.80859 9.19239 1.62598 6.5 1.62598C3.80761 1.62598 1.625 3.80859 1.625 6.50098C1.625 9.19336 3.80761 11.376 6.5 11.376Z"
        stroke={props.color || ThemeColors.HeavyGray}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 3.65723V6.50098H9.34375"
        stroke={props.color || ThemeColors.HeavyGray}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default TimeClockIcon;
