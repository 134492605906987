import styled from 'styled-components';
import { ThemeColors, ThemeFonts } from 'styles/constants';

// Nav Bar
export const NavBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 4px;
`;

export const NavBarItem = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
  ${(props) => (props.color ? `color: ${props.color};` : '')};
`;

export const NavBarItemContent = styled(ThemeFonts.BodyMedium)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 9px 40px;
  gap: 10px;
  cursor: pointer;
`;

export const NavBarUnderline = styled.div`
  border-bottom: 1px solid ${ThemeColors.CloudyGray};
`;

export const NavBarUnderlineActive = styled.div<{ color?: string }>`
  flex: 1;
  margin: 0px 16px;
  border-bottom: 3px solid ${(props) => props.color ?? ''};
  border-radius: 3px 3px 0px 0px;
`;
