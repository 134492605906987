import React, { ReactNode, useState, useId } from 'react';

import { ThemeColors } from 'styles/constants';
import { NavBarWrapper, NavBarItem, NavBarUnderline, NavBarUnderlineActive, NavBarItemContent } from './navBar.styles';

interface props {
  items: { label: string; icon: ReactNode; onClick: () => void }[];
}

const NavBar = ({ items }: props) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const onClick = (index: number, parentHandler: () => void) => {
    setActiveTabIndex(index);
    parentHandler();
  };

  return (
    <NavBarUnderline>
      <NavBarWrapper>
        {items.map((item, index) => (
          <NavBarItem
            key={useId()}
            color={index === activeTabIndex ? ThemeColors.TassoBrightBlue : ThemeColors.HeavyGray}
            onClick={() => onClick(index, item.onClick)}
          >
            <NavBarItemContent>
              {item.icon}
              {item.label}
            </NavBarItemContent>
            <NavBarUnderlineActive
              color={index === activeTabIndex ? ThemeColors.TassoBrightBlue : ThemeColors.TassoWhite}
            />
          </NavBarItem>
        ))}
      </NavBarWrapper>
    </NavBarUnderline>
  );
};

export default NavBar;
