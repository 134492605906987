import styled from 'styled-components';
import { ThemeColors, ThemeFonts } from 'styles/constants';

// Search
export const AddressSearchContainer = styled(ThemeFonts.BodyLarge)`
  display: flex;
  align-items: center;
  padding: 12px 8px 12px 16px;
  border: 1px solid ${ThemeColors.TassoViolet};
  border-radius: 4px;
  justify-content: space-between;
`;

export const AddressSearchInput = styled.input<{ color?: string }>`
  display: flex;
  flex: 1;
  font: inherit;
  border: none;
  outline: none;
  ${(p) => (p.color ? `color: ${p.color}` : '')}
`;
