import React, { SVGProps } from 'react';

export const RotateDeviceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_5344_11762)">
      <path
        d="M50 54H20C18.9391 54 17.9217 53.5786 17.1716 52.8284C16.4214 52.0783 16 51.0609 16 50V27"
        stroke="#5067EB"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 10H44C45.0609 10 46.0783 10.4214 46.8284 11.1716C47.5786 11.9217 48 12.9391 48 14V37"
        stroke="#5067EB"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M44 48L50 54L44 60" stroke="#5067EB" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20 4L14 10L20 16" stroke="#5067EB" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_5344_11762">
        <rect width="64" height="64" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default RotateDeviceIcon;
