import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful';

export interface TypeLocationPanelFields {
  name: EntryFieldTypes.Symbol;
  helpIcon: EntryFieldTypes.AssetLink;
  titleText?: EntryFieldTypes.Symbol;
  bodyText?: EntryFieldTypes.RichText;
}

export type TypeLocationPanelSkeleton = EntrySkeletonType<TypeLocationPanelFields, 'locationPanel'>;
export type TypeLocationPanel<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<
  TypeLocationPanelSkeleton,
  Modifiers,
  Locales
>;

export function isTypeLocationPanel<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeLocationPanel<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'locationPanel';
}

export type TypeLocationPanelWithoutLinkResolutionResponse = TypeLocationPanel<'WITHOUT_LINK_RESOLUTION'>;
export type TypeLocationPanelWithoutUnresolvableLinksResponse = TypeLocationPanel<'WITHOUT_UNRESOLVABLE_LINKS'>;
export type TypeLocationPanelWithAllLocalesResponse<Locales extends LocaleCode = LocaleCode> = TypeLocationPanel<
  'WITH_ALL_LOCALES',
  Locales
>;
export type TypeLocationPanelWithAllLocalesAndWithoutLinkResolutionResponse<Locales extends LocaleCode = LocaleCode> =
  TypeLocationPanel<'WITHOUT_LINK_RESOLUTION' | 'WITH_ALL_LOCALES', Locales>;
export type TypeLocationPanelWithAllLocalesAndWithoutUnresolvableLinksResponse<
  Locales extends LocaleCode = LocaleCode,
> = TypeLocationPanel<'WITHOUT_UNRESOLVABLE_LINKS' | 'WITH_ALL_LOCALES', Locales>;
