import React from 'react';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { Entry } from 'contentful';
import TimeClockIcon from 'assets/TimerClockIcon';
import HelpButton from 'components/SharedComponents/HelpButton';
import NextButton from 'components/SharedComponents/NextButton';
import { TypePhaseSummarySkeleton, TypePhaseWithoutUnresolvableLinksResponse } from 'contentmodels';

import { ThemeColors, ThemeFonts } from 'styles/constants';
import {
  BarWrapper,
  HeaderWrapper,
  PhaseNumber,
  PhasePanelContainerStyles,
  PhaseTitleSectionWrapper,
  PhaseTitleWrapper,
  ProgressBarWrapper,
  ProgressPhaseWrapper,
  StepImage,
  StepWrapper,
  StepsWrapper,
  SummaryTextWrapper,
  SummaryWrapper,
} from './phasePanel.styles';
import { TimeWrapper } from '../GenericStyles/generic.styles';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 3,
  borderRadius: 40,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: ThemeColors.DarkGray,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 40,
    backgroundColor: ThemeColors.TassoWhite,
  },
}));

const PhasePanel = (props: {
  panel: TypePhaseWithoutUnresolvableLinksResponse;
  order: number;
  phases: string[];
  openHelpModal: () => void;
}) => {
  const { panel, order, phases } = props;
  const { titleText, phaseSummary, subText, nextButtonText, tertiaryText, timeText } = panel.fields;
  return (
    <PhasePanelContainerStyles>
      <HeaderWrapper>
        <BarWrapper>
          <ProgressBarWrapper key={order}>
            {phases &&
              phases.map((phase: string, index: number) => (
                <ProgressPhaseWrapper key={index}>
                  <BorderLinearProgress variant="determinate" value={phases.indexOf(titleText) === index ? 100 : 0} />
                  <ThemeFonts.LabelSmall color={ThemeColors.TassoWhite}>{phase}</ThemeFonts.LabelSmall>
                </ProgressPhaseWrapper>
              ))}
          </ProgressBarWrapper>
          <HelpButton {...props} />
        </BarWrapper>
        <PhaseTitleSectionWrapper>
          <PhaseTitleWrapper>
            <PhaseNumber>{phases && titleText && <>{phases.indexOf(titleText) + 1}</>}</PhaseNumber>
            {titleText && <ThemeFonts.DisplaySmall color={ThemeColors.TassoWhite}>{titleText}</ThemeFonts.DisplaySmall>}
          </PhaseTitleWrapper>
          {timeText && (
            <TimeWrapper>
              <TimeClockIcon height={16} width={16} color={ThemeColors.CloudyGray} />
              <ThemeFonts.BodyLarge color={ThemeColors.CloudyGray}>{timeText}</ThemeFonts.BodyLarge>
            </TimeWrapper>
          )}
        </PhaseTitleSectionWrapper>
      </HeaderWrapper>
      <SummaryWrapper>
        <SummaryTextWrapper>
          {subText && <ThemeFonts.HeadlineSmall>{subText}</ThemeFonts.HeadlineSmall>}
          {tertiaryText && <ThemeFonts.BodyLarge>{tertiaryText}</ThemeFonts.BodyLarge>}
        </SummaryTextWrapper>
        <StepsWrapper>
          {phaseSummary &&
            phaseSummary.map(
              (
                step: Entry<TypePhaseSummarySkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string> | undefined,
                index: number
              ) => (
                <StepWrapper key={index}>
                  {step?.fields.summaryImage?.fields.file?.url && step?.fields.summaryImage?.fields.title && (
                    <StepImage>
                      <img
                        src={step?.fields.summaryImage?.fields.file?.url}
                        alt={step?.fields.summaryImage?.fields.title}
                      />
                    </StepImage>
                  )}
                  {step?.fields.summaryText && <ThemeFonts.BodyLarge>{step?.fields.summaryText}</ThemeFonts.BodyLarge>}
                </StepWrapper>
              )
            )}
        </StepsWrapper>
        <NextButton nextButtonText={nextButtonText} />
      </SummaryWrapper>
    </PhasePanelContainerStyles>
  );
};

export default PhasePanel;
