import React, { SVGProps } from 'react';

export const ListBulletsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_5122_3755)">
      <path d="M7.90625 6.25H19.4062" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.90625 12H19.4062" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.90625 17.75H19.4062" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M3.95312 12.7188C4.35008 12.7188 4.67188 12.397 4.67188 12C4.67188 11.603 4.35008 11.2812 3.95312 11.2812C3.55617 11.2812 3.23438 11.603 3.23438 12C3.23438 12.397 3.55617 12.7188 3.95312 12.7188Z"
        fill="currentColor"
      />
      <path
        d="M3.95312 6.96875C4.35008 6.96875 4.67188 6.64695 4.67188 6.25C4.67188 5.85305 4.35008 5.53125 3.95312 5.53125C3.55617 5.53125 3.23438 5.85305 3.23438 6.25C3.23438 6.64695 3.55617 6.96875 3.95312 6.96875Z"
        fill="currentColor"
      />
      <path
        d="M3.95312 18.4688C4.35008 18.4688 4.67188 18.147 4.67188 17.75C4.67188 17.353 4.35008 17.0312 3.95312 17.0312C3.55617 17.0312 3.23438 17.353 3.23438 17.75C3.23438 18.147 3.55617 18.4688 3.95312 18.4688Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_5122_3755">
        <rect width="23" height="23" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default ListBulletsIcon;
