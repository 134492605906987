import React, { SVGProps } from 'react';

export const MapTrifoldIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_5122_3748)">
      <path d="M9.125 17.0312V4.09375" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.875 6.96875V19.9062" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M9.125 17.0312L3.375 18.4688V5.53125L9.125 4.09375L14.875 6.96875L20.625 5.53125V18.4688L14.875 19.9062L9.125 17.0312Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5122_3748">
        <rect width="23" height="23" fill="white" transform="translate(0.5 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default MapTrifoldIcon;
