import React, { SVGProps } from 'react';

export const BackArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1085_6765)">
      <path
        d="M6 15L1 10L6 5"
        stroke="currentColor"
        strokeWidth="1.1743"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1085_6765">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BackArrowIcon;
