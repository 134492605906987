import React, { SVGProps } from 'react';

export const ServiceErrorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="68" height="53" viewBox="0 0 68 53" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity="0.6">
      <path
        opacity="0.2"
        d="M38.375 1L50.25 12.875V2.25C50.25 1.91848 50.1183 1.60054 49.8839 1.36612C49.6495 1.1317 49.3315 1 49 1H38.375Z"
        fill="currentColor"
      />
      <path
        opacity="0.2"
        d="M27.125 1L40.875 14.75H29.625L17.75 2.875V2.25C17.75 1.91848 17.8817 1.60054 18.1161 1.36612C18.3505 1.1317 18.6685 1 19 1H27.125Z"
        fill="currentColor"
      />
      <path
        d="M49 1H19C18.3096 1 17.75 1.55964 17.75 2.25V13.5C17.75 14.1904 18.3096 14.75 19 14.75H49C49.6904 14.75 50.25 14.1904 50.25 13.5V2.25C50.25 1.55964 49.6904 1 49 1Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.125 1L40.875 14.75"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.375 1L50.25 12.875"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.75 2.875L29.625 14.75"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M44 14.75V21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24 14.75V21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 21.75L67 21.75" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <path
      d="M33 51C37.4183 51 41 46.0751 41 40C41 33.9249 37.4183 29 33 29C28.5817 29 25 33.9249 25 40C25 46.0751 28.5817 51 33 51Z"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56 51C60.4183 51 64 46.0751 64 40C64 33.9249 60.4183 29 56 29C51.5817 29 48 33.9249 48 40C48 46.0751 51.5817 51 56 51Z"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 49.3196C8.8585 50.1642 9.91983 50.7191 11.0573 50.9181C12.1947 51.1171 13.3602 50.9518 14.4145 50.4419C15.4689 49.932 16.3675 49.0991 17.0032 48.0426C17.6388 46.9861 17.9846 45.7507 17.9992 44.4839C18.0139 43.2171 17.6968 41.9725 17.0857 40.8986C16.4747 39.8247 15.5956 38.9669 14.5534 38.4277C13.5112 37.8885 12.3499 37.6906 11.2082 37.8578C10.0666 38.0249 8.99274 38.5499 8.115 39.3702L10 29H18"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ServiceErrorIcon;
